import React from "react";
import "./slideShow.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SlideShow = () => {
   const option = {
     loop: true,
     responsiveClass: true,
     center: true,
     items: 1,
     margin: 0,
     autoplay: true,
     autoplayTimeout: 8500,
     smartSpeed: 450,

   
   };

  return (
    <div className="max-width">
      <div className="slideShow  ">
        <OwlCarousel className="owl-theme" {...option}>
          <div class="item">
            <h4>
              "I was introduced to Chocodate by a friend of mine last Ramadan in
              Cairo, Egypt and I liked them a lot. This year I bought 2 boxes to
              fill me in on my sweet tooth. Yesterday I took a box to a
              gathering, there were all sorts of nationalities and religions and
              the wiped the whole box out! 🙂"
            </h4>
            <br />
            <p>Nancy El Helbawi (Kuwait)</p>
          </div>
          <div class="item">
            <h4>
              "I must say that "Chocodates" are the most delicious combination
              of ingredients that i have ever tasted. Dates, almonds and
              chocolate together, the best! I had the opportunity to taste it
              when my husband brought some from Dubai. I live in Brazil and i
              would like to know if you sell them through the internet or
              abroad. I would really love to eat it again."
            </h4>
            <br />
            <p>Dulce F Paletta de Cerqueira (Brazil) </p>
          </div>
          <div class="item">
            <h4>
              " We had a guest from Dubai...the beautiful country...they bought
              some gifts, including Chocodate...I have never eaten something as
              tasty as them."
            </h4>
            <br />
            <p>Zeenath Ismail (South Africa) </p>
          </div>
          <div class="item">
            <h4>
              "My dad brought Chocodate from Dubai. I love it! smile emoticon."
            </h4>
            <br />
            <p>Asgar Ali (UK)</p>
          </div>
          <div class="item">
            <h4>
              "I find your Chocodate delicious and very special. My family loves
              it!"
            </h4>
            <br />
            <p>Dennis Soo Ah Seng (Singapore)</p>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default SlideShow;
