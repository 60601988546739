import React, { useState } from "react";
import "./Products.scss";
import List from "../../components/List/List";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import sample from "../../img/banner.mp4";

const Products = () => {
  const catId = parseInt(useParams().id);

  const [selectSubCategory, setSelectSubCategory] = useState([]);

  const [maxPrice, setMaxPrice] = useState(200);
  const [sort, setSort] = useState(null);

  const { data, loading, error } = useFetch(
    `/sub-categories?[filters][categories][id][$eq]=${catId}`
  );


  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectSubCategory(
      isChecked
        ? [...selectSubCategory, value]
        : selectSubCategory.filter((item) => item !== value)
    );
  };

  return (
    <div className="max-width">
      <div className="home">
        <div className="hero_wrappe ">
          <div className="video">
            <video className="videoTag" autoPlay loop muted>
              <source src={sample} type="video/mp4" />
            </video>
          </div>

        </div>
      </div>

      <div className="products __wrap">
        <div className="left">
          <div className="filterItem">
            <h2>Product Categories</h2>
            {data?.map((item) => (
              <div className="inputItem" key={item.id}>
                <input
                  type="checkbox"
                  id={item.id}
                  value={item.id}
                  onChange={handleChange}
                />
                <label htmlFor={item.id}>{item.attributes.title}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="right">
          <div className="free_banner">
            {/*<img src={require("../../img/free.png")} /> */}
          </div>
          <List
            catId={catId}
            maxPrice={maxPrice}
            sort={sort}
            subCats={selectSubCategory}
          />
        </div>
      </div>
    </div>
  );
};

export default Products;
