import React from "react";
import "./Style.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";

const Succsess = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="succsess">
      <div className="card_wrap">
        <div className="logo"></div>
        <h1>
          Your Order is{" "}
          <em style={{ color: "#5ce65c", fontStyle: "normal" }}>Confirmed</em>
          <br />
          Thank you for your purchase!
        </h1>
        <div className="btn">
          <Link to="/products/1" onClick={scrollToTop}>
            <button>Continue Shopping</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Succsess;
