import React from 'react'
import "./loading.scss"

const Loading = () => {
  return (
    <div className="loading_wrp">
      <div className="loading_msg">
        <p style={{color:"black"}} >please wait...</p>
      </div>
    </div>
  );
}

export default Loading
