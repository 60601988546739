import React from "react";
import "./bites.scss";
import bites from "../../img/bite2.png";
import { Link } from "react-router-dom";

const Bites = () => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          backgroundImage: `url(${bites})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="_wegan">
          <img src={require("../../img/bt_log2.png")} alt="" />
        </div>
        <div className="des">
          <p>
            Introducing Chocodate Bites, the perfect blend of nutrition and
            indulgence designed with kids in mind. <br /> Each bite-sized piece
            features a tender medjool date paste filled with mixed nuts, coated
            in smooth milk chocolate, strawberry or vanilla. <br /> These
            delightful treats offer a balanced combination of natural sweetness,
            wholesome nuts, and the irresistible taste of chocolate, making them
            an ideal choice for children's snacking occasions. Whether at home,
            school, or on the go, Chocodate Bites are a wholesome snack option
            that kids will love, providing a satisfying treat that parents can
            feel good about. <br /> Encourage healthy habits with a snack that
            combines fun and nutrition in every bite.
          </p>
        </div>
        <div className="bt_brandInfo_wrp">
          <Link to={"/product/101"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/bt_01.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #d7df23" }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>
          <Link to={"/product/100"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/bt_02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #df185a" }}
              >
                <button>Order Now</button>
              </div>
            </div>{" "}
          </Link>

          <Link to={"/product/102"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/bt_03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #7e579e" }}
              >
                <button>Order Now</button>
              </div>
            </div>{" "}
          </Link>
        </div>
        <div className="_symbol">
          <img src={require("../../img/bites_xt.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Bites;
