import React, { useState } from "react";
import "./Product.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BalanceIcon from "@mui/icons-material/Balance";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Product = () => {
  const id = useParams().id;
  const [selectImage, setSelectImage] = useState("img");
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();

  const scrollDown = () => {
    window.scroll(0, 500);
  };

  const { data, loading, error } = useFetch(`/products/${id}?populate=*`);

  return (
    <div className="max-width">
      <div className="back_button">
        <a href="/products/1">
          {" "}
          <KeyboardBackspaceIcon /> Back to Shop
        </a>
      </div>
      <div className="product">
        {loading ? (
          "loading"
        ) : (
          <div className="product_card">
            <div className="left">
              <div className="images">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img?.data?.attributes?.url
                  }
                  alt=""
                  onClick={() => setSelectImage("img")}
                />
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img2?.data?.attributes?.url
                  }
                  alt=""
                  onClick={() => setSelectImage("img2")}
                />
              </div>

              <div className="mainImg">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes[selectImage]?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
            </div>

            <div className="right">
              <h2>{data?.attributes?.title}</h2>

              <span className="price">AED {data?.attributes?.price}</span>
              <h5>Weight - {data?.attributes?.weight}</h5>
              <p>{data?.attributes?.desc}</p>
              <div className="quantity">
                <button
                  onClick={() =>
                    setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                  }
                >
                  -
                </button>
                {quantity}
                <button onClick={() => setQuantity((prev) => prev + 1)}>
                  +
                </button>
              </div>

              <div onClick={scrollDown}>
                <div
                  className="add"
                  onClick={() => {
                    if (data?.attributes?.outofstock) {
                      toast.error("Temporary out of stock", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                      });
                    } else {
                      dispatch(
                        addToCart({
                          id: data.id,
                          title: data.attributes.title,
                          desc: data.attributes.desc,
                          price: data.attributes.price,
                          weight: data.attributes.weight,
                          img: data.attributes.img.data.attributes.url,
                          quantity,
                        })
                      );
                    }
                  }}
                >
                  <ToastContainer />
                  <AddShoppingCartIcon />
                  ADD TO CART
                </div>
              </div>
            </div>
          </div>
        )}
        <br />
        <br />
        <br />
        <div className="tranding">
          <h1>Top Selling Products</h1>
          <FeaturedProducts type="featured" />
        </div>
      </div>
    </div>
  );
};

export default Product;
