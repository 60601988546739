import React from 'react'
import "./brandInfo.scss";
import Gourmet from '../../components/gourmet/Gourmet';

const GourmetPage = () => {
  return (
    <div className="pro_wrp">
      <Gourmet />

    </div>
  );
}

export default GourmetPage
