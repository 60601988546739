import React, { useEffect, useState } from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import one from "../../img/one.webm";
import two from "../../img/two.webm";
import three from "../../img/three.webm";
import backImg from "../../img/banner.jpg";
import { Link } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import Popupdv from "../../components/popup/Popup";
import SlideShow from "../../components/slideshow/SlideShow";
import Social from "../../components/Social/Social";
import Modal from "../../components/modal/Modal";

const Home = () => {


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <div className="home">
        
        <div className="hero_wrappe ">
          <div className="video">
            <video className="videoTag" autoPlay loop muted>
              <source src={sample} type="video/mp4" />
            </video>
          </div>
          {
            <div className="heading">
              <p>
                At Chocodate, we’ve created a luxurious experience with a
                wholesome approach to health and wellness that will give you a
                natural boost of goodness
              </p>
            </div>
          }
        </div>
        <div className="heading-two">
          <h1 style={{ fontSize: "50px" }}>Simply Delicious</h1>
        </div>

        <FeaturedProducts type="featured" />

        <div className="text_ing">
          <h1>Our Ingredients</h1>
          <div className="item_one">
            <div></div>
            <div className="item_one_sec">
              <img src={require("../../img/almond.webp")} />
              <div className="text_con">
                <p>
                  Experience An International Fusion Of Flavors With Our
                  Sumptuous Arabian Dates And Mediterranean Almonds, Draped In A
                  Delicate Layer Of Silky-Smooth Belgian Chocolate.
                </p>
              </div>
            </div>
            <div></div>
          </div>
          <div className="main_img">
            <img src={require("../../img/dates.jpg")} alt="" />
          </div>
          <div className="item_two">
            <div className="item_two_in">
              <div className="item_two_in_img">
                <img src={require("../../img/date.webp")} />
              </div>
              <div className="item_two_in_text">
                <p>
                  Arabian Dates are by nature, chewy with a sweet flavor,
                  boasting an excellent nutrition profile too. High in
                  antioxidants, including dates in your diet is a great way to
                  increase your fiber intake.
                </p>
              </div>
            </div>
            <div className="item_two_in">
              <div className="item_two_in_img">
                <img src={require("../../img/choco.webp")} />
              </div>
              <div className="item_two_in_text">
                <p>
                  Belgian chocolate has been the food of champions and a
                  favorite of Kings. Belgian chocolate tends to have a higher
                  cocoa content, which occurs most naturally in dark chocolate.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="in_banner_con  ">
          <div className="in_banner __wrap ">
            <div className="text_bannr">
              <h2>
                At Chocodate, we’ve created a luxurious experience with a
                wholesome approach to health and wellness that will give you a
                natural boost of goodness, alongside the fullness of our
                flavours, to help you live your best life. All our products are
                free of additives and harmful preservatives and we are proudly
                made in the U.A.E. We believe in creating a delicious offering
                for those demanding lifestyles and we are focused on bringing a
                world of flavours to you.
              </h2>
            </div>
            <div className="img_bannr">
              <img src={require("../../img/dates.webp")} alt="" />
            </div>
          </div>
        </div>

        <div className="product_info_section_one">
          <div className="img_wrap">
            <img src={require("../../img/sus.png")} />
          </div>
          <div className="text">
            <h1>Sustainability</h1>
            <div className="strong">
              <strong>
                Our ambition is to operate in a very sustainable and ethical
                environment. Here’s how
              </strong>
            </div>

            <ul>
              <li>
                Our factory runs on 80% solar energy with over 3000 solar panels
              </li>
              <li>
                We aim to reduce our greenhouse emissions bu increasing the use
                of renewable electricity
              </li>
              <li>
                We are aiming to reduce food wastage by encouraging mindful
                snacking habits with our clear and conscise labelling.
              </li>
              <li>
                Using the latest technology, we aim to increase our recyclable
                and reusable packaging.
              </li>
              <li>
                Our production facilities are clearly certified and ensure food
                safety at the highest standards.
              </li>
            </ul>
          </div>
        </div>

        <div className="testimonial">
          <h2>What customer say about us</h2>
          <br />
          <br />
          <div className="test_wrap">
            <SlideShow />
          </div>
        </div>
        <Newsletter />
      </div>
    </div>
  );
};

export default Home;
