import React from 'react'
import "./vegan.scss";
import bkg from "../../img/bk.png";
import { Link } from 'react-router-dom';


const Vegan = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          backgroundImage: `url(${bkg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="wegan">
          <img src={require("../../img/vegan.png")} alt="" />
        </div>
        <div className="des">
          <p>
            Introducing Chocodate Vegan, a guilt-free indulgence crafted for
            those who cherish both flavor and ethical choices. <br /> Each piece
            features a plump medjool date filled with a crunchy almond, all
            coated in rich, dairy-free chocolate. This vegan-friendly treat
            promises a harmonious blend of natural sweetness and satisfying
            crunch, making it an ideal choice for discerning palates seeking
            plant-based delights. <br /> Enjoy Chocodate Vegan as a wholesome
            snack or a thoughtful gift, knowing that every bite is crafted with
            compassion and quality in mind.
          </p>
        </div>
        <div className="brandInfo_wrp">
          <Link to={"/product/91"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/01.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #743f2b" }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>
          <Link to={"/product/94"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #482565" }}
              >
                <button>Order Now</button>
              </div>
            </div>{" "}
          </Link>

          <Link to={"/product/92"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #0872ba" }}
              >
                <button>Order Now</button>
              </div>
            </div>{" "}
          </Link>
          {/* <div className="item">
            <div className="item_img">
              <img src={require("../../img/04.png")} alt="" />
            </div>
            <div
              className="item_price"
              style={{ borderTop: "2px solid #0e6e68" }}
            >
              <button>pre order</button>
            </div>
          </div>{" "} */}
          <Link to={"/product/90"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/05.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #221f1f" }}
              >
                <button>Order Now</button>
              </div>
            </div>
          </Link>
        </div>
        <div className="symbol">
          <img src={require("../../img/symbol2.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Vegan;
