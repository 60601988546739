import React from 'react'
import Bites from '../../components/bites/Bites';
import "./brandInfo.scss";

const BitesPage = () => {
  return (
    <div className="pro_wrp">
      <Bites />
    </div>
  );
}

export default BitesPage
