import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./mobile.css";

const Navbar = () => {
  const [open, setOpen] = useState(false);
    const [close, setClose] = useState(true);
  const products = useSelector((state) => state.cart.products);

  const [show,setShow]  = useState(false)


  const toggleDropdown = () => {
    setShow(!show);
  };
const handleMouseEnter = () => {
  setShow(true);
};

const handleMouseLeave = () => {
  setShow(false);
};

useEffect(()=>{
  handleMouseLeave()
},[Link])


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="my-navbar">
        <nav class="navbar navbar-expand-lg ">
          <div class="container-fluid">
            <div className="logo-top">
              <div>
                <button
                  class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="toggler-icon top-bar"></span>
                  <span class="toggler-icon middle-bar"></span>
                  <span class="toggler-icon bottom-bar"></span>
                </button>
              </div>

              <div className="center">
                <Link onClick={scrollToTop} className="link " to="/">
                  <img src={require("../../img/logo.webp")} alt="" />
                </Link>
              </div>

              <div className="item">
                <div className="icons">
                  <div className="cartIcon" onClick={() => setOpen(!open)}>
                    <ShoppingCartIcon />
                    <span>{products.length}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/products/1"
                  >
                    Shop
                  </Link>
                </li>

                <div className="item dropDown" onClick={toggleDropdown}>
                  <span onClick={scrollToTop}>
                    <em
                      style={{
                        color: "#f1c40f",
                        fontSize: "12px",
                        fontStyle: "normal",
                      }}
                    >
                      New
                    </em>{" "}
                    Release
                  </span>
                  {show && (
                    <div className="dropDownMob">
                      <div className="nav-item">
                        <Link className="link hover" to="/gourmet">
                          Gourmet
                        </Link>
                      </div>
                      <div className="nav-item">
                        <Link className="link hover" to="/vegan">
                          Vegan
                        </Link>
                      </div>
                      <div className="nav-item">
                        <Link className="link hover" to="/bites">
                          Bites
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/promotion"
                  >
                    Promotions
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* ------------desktop wall paper----------------- */}

        <div className="wrapper" onMouseEnter={handleMouseLeave}>
          <div className="center">
            <Link onClick={scrollToTop} className="link hover" to="/">
              <img src={require("../../img/logo.webp")} alt="" />
            </Link>
          </div>
          <div></div>

          <div className="right">
            <div className="item">
              <Link
                onClick={scrollToTop}
                className="link hover"
                to="/products/1"
              >
                Shop
              </Link>
            </div>
            {/* <div className="item">
            <Link onClick={scrollToTop} className="link" to="/new-brands">
              <em
                style={{
                  color: "#f1c40f",
                  fontSize: "12px",
                  fontStyle: "normal",
                }}
              >
                New
              </em>{" "}
              Release
            </Link>
          </div> */}

            <div
              className="item dropDownDeskTopWrp "
              onMouseEnter={handleMouseEnter}
            >
              <span onClick={scrollToTop} className="hover">
                <em
                  style={{
                    color: "#f1c40f",
                    fontSize: "12px",
                    fontStyle: "normal",
                  }}
                >
                  New
                </em>{" "}
                Release
              </span>
              <>
                {show && (
                  <div className="dropDownDeskTop">
                    <div className="nav-item" onClick={handleMouseLeave}>
                      <Link className="link hover" to="/gourmet">
                        Gourmet
                      </Link>
                    </div>
                    <div className="nav-item" onClick={handleMouseLeave}>
                      <Link className="link hover" to="/vegan">
                        Vegan
                      </Link>
                    </div>
                    <div className="nav-item" onClick={handleMouseLeave}>
                      <Link className="link hover" to="/bites">
                        Bites
                      </Link>
                    </div>
                  </div>
                )}
              </>
            </div>

            <div className="item">
              <Link
                onClick={scrollToTop}
                className="link hover"
                to="/promotion"
              >
                Promotions
              </Link>
            </div>

            <div className="item">
              <Link onClick={scrollToTop} className="link hover" to="/about">
                About
              </Link>
            </div>

            <div className="item">
              <Link onClick={scrollToTop} className="link hover" to="/contact">
                Contact
              </Link>
            </div>

            <div className="item">
              <div className="icons">
                <div className="cartIcon" onClick={() => setOpen(!open)}>
                  <ShoppingCartIcon />
                  <span>{products.length}</span>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        {open && <Cart />}
      </div>
    </>
  );
};

export default Navbar;
