import React from 'react'
import Vegan from '../../components/Vegan/Vegan';
import "./brandInfo.scss";
const VeganPage = () => {
  return (
    <div className="pro_wrp">

      <Vegan />

    </div>
  );
}

export default VeganPage
