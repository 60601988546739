import React from "react";
import "./Pay_Icon.scss";
import Copyright from "../Copyright/Copyright";

const Pay_Icon = () => {
  return (
    <div className="max-width">
      <div className="pay_icon">
        <span>We Accept </span>
        <div className="icons">
          <img src={require("../../img/visa.png")} />
          <img src={require("../../img/master.png")} />
          <img src={require("../../img/apple.png")} />
          <img src={require("../../img/gpay.png")} />
        </div>
        <Copyright />
      </div>
    </div>
  );
};

export default Pay_Icon;
